import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { WebApiService } from 'src/app/shared/services/web-api.service';
import { Page } from 'src/app/shared/models/page';
import { Observable,from } from 'rxjs';
import { PagedData } from 'src/app/shared/models/paged-data';
import { Supplier, SupplierUpdateResult, SupplierValidationResult } from 'src/app/shared/models/supplier.model';
import { HttpParams } from '@angular/common/http';
import { PageParams } from 'src/app/shared/common/Enums';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiUrlConstants } from "src/app/shared/common/ApiUrlConstants";
import { SupplierProduct } from 'src/app/shared/models/supplier-product.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  @Output() supplierProductRecord: any = new EventEmitter();

  constructor(private webApiService: WebApiService, private snack: MatSnackBar) { }

  /**
   * Get all suppliers list with paginated options.
   * @param pageMetadata Page Metadata i.e. PAGE OFFSET, PAGE SIZE, TOTAL PAGES etc..
   */
  getPaginatedSuppliers(pageMetadata: Page): Observable<PagedData<Supplier>> {
    let apiUrl = "/supplier/";

    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, pageMetadata.size)
      .set(PageParams.page, pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, pageMetadata.serachParam)
      .set(PageParams.isDelete, pageMetadata.isDelete)
      .set(PageParams.sortColoumn, pageMetadata.sortColoumn)
      .set(PageParams.sortOrder, pageMetadata.sortOrder);

    return this.webApiService
      .getPaginatedRequest<PagedData<Supplier>>(apiUrl, params)
      .pipe(
        map((paginatedSupplier) => {
          // Set page metadata for frontend table properties
          paginatedSupplier.page.totalElements = paginatedSupplier.count;
          paginatedSupplier.page.size = pageMetadata.size;
          paginatedSupplier.page.totalPages = Math.floor(
            paginatedSupplier.count / pageMetadata.size
          );
          paginatedSupplier.page.pageNumber = pageMetadata.pageNumber;
          paginatedSupplier.page.serachParam = pageMetadata.serachParam;
          return paginatedSupplier;
        })
      );
  }

  /**
   * Get all suppliers list.
   */
  getAllSuppliers(): Promise<Supplier[]> {
    let apiUrl = "/supplier/";
    return this.webApiService.getRequest<Supplier[]>(apiUrl);
  }

  /**
   * Get supplier detail by supplier id.
   * @param _supplierId Supplier Id.
   */
  getSupplierById(_supplierId: number): Promise<Supplier> {
    let apiUrl = `/supplier/${_supplierId}`;
    return this.webApiService.getRequest<Supplier>(apiUrl);
  }

  /**
   * Add new supplier.
   * @param _supplierDetail Supplier form data.
   */
  addSupplier(_supplierDetail: Supplier, supplierId?: any): Promise<any> {
    let apiUrl = `/supplier/add`;
    return this.webApiService.postRequest<any>(apiUrl, { ..._supplierDetail, supplierId });
  }

  /**
   * Update any existing supplier details by supplier id.
   * @param _supplierId To be updated supplier id.
   * @param _supplierDetail Supplier form data.
   */
  updateSupplier(_supplierId: number, _supplierDetail: Supplier): Promise<Supplier> {
    let apiUrl = `/supplier/add`;
    return this.webApiService.postRequest<Supplier>(apiUrl, {..._supplierDetail,_supplierId})
  };
  

  /**
 * Update any existing supplier details by supplier id.
 * @param _supplierId To be updated supplier id.
 * @param _supplierDetail Supplier form data.
 */
  validateSupplierName(emailAddressArr: string[]): Promise<SupplierValidationResult> {
    let apiUrl = `/supplier/validate`;
    const emailAddresses = { emailAddressArr };
    return this.webApiService.postRequest<SupplierValidationResult>(apiUrl, emailAddresses);
  }

  /**
* Update any existing supplier details by supplier id.
* @param _supplierId To be updated supplier id.
* @param _supplierDetail Supplier form data.
*/
  getSupplierUpdateDataByUserId(_userId: any): Promise<SupplierUpdateResult> {
    let apiUrl = `/supplier/fetch/userid/${_userId}`;
    return this.webApiService.getRequest<SupplierUpdateResult>(apiUrl);
  }

  getSearchResult(searchTerms: any): Promise<any> {
    let apiUrl = `/supplier/search/${searchTerms}`;
    return this.webApiService.getRequest<any>(apiUrl);
  }

  /**
   * Remove any existing supplier by supplier id.
   * @param _supplierId To be removed supplier id.
   */
  deleteSupplier(_supplierId: number): Promise<Supplier> {
    let apiUrl = `/supplier/${_supplierId}`;
    return this.webApiService.deleteRequest<Supplier>(apiUrl);
  }

  //Restore
  restoreSupplier(_supplierId: number): Promise<Supplier> {
    let apiUrl = `/supplier/restore/${_supplierId}`;
    return this.webApiService.putRequest<Supplier>(apiUrl, { _supplierId: _supplierId });
  }

  /**
   * Get all suppliers list with paginated options.
   * @param pageMetadata Page Metadata i.e. PAGE OFFSET, PAGE SIZE, TOTAL PAGES etc..
   */
  getPaginatedSupplierProducts(pageMetadata: Page, supplierId: number): Observable<PagedData<SupplierProduct[]>> {
    let apiUrl = `/supplier/getSupplierProductDetails/${supplierId}`;

    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, pageMetadata.size)
      .set(PageParams.page, pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, pageMetadata.serachParam)
      .set(PageParams.sortColoumn, pageMetadata.sortColoumn)
      .set(PageParams.sortOrder, pageMetadata.sortOrder);

    return this.webApiService
      .getPaginatedRequest<PagedData<SupplierProduct[]>>(apiUrl, params)
      .pipe(
        map((paginatedSupplierShipPOs) => {
          // Set page metadata for frontend table properties
          paginatedSupplierShipPOs.page.totalElements = paginatedSupplierShipPOs.count;
          paginatedSupplierShipPOs.page.size = pageMetadata.size;
          paginatedSupplierShipPOs.page.totalPages = Math.floor(
            paginatedSupplierShipPOs.count / pageMetadata.size
          );
          paginatedSupplierShipPOs.page.pageNumber = pageMetadata.pageNumber;
          paginatedSupplierShipPOs.page.serachParam = pageMetadata.serachParam;
          return paginatedSupplierShipPOs;
        })
      );
  }


  /**
   * Get supplier product detail by supplier product id.
   * @param _supplierProductId Supplier Product Id.
   */
  getSupplierProductById(_supplierProductId: number): Promise<SupplierProduct> {
    let apiUrl = `/supplier/supplierProduct/${_supplierProductId}`;
    return this.webApiService.getRequest<SupplierProduct>(apiUrl);
  }

  /**
   * Update any existing supplier product details by supplier product id.
   * @param _supplierProductId To be updated supplier product id.
   * @param _supplierProductDetail Supplier product form data.
   */
  updateSupplierProduct(_supplierProductId: number, _supplierProductDetail: SupplierProduct): Promise<SupplierProduct> {
    let apiUrl = `/supplier/supplierProduct/${_supplierProductId}`;
    return this.webApiService.putRequest<SupplierProduct>(apiUrl, _supplierProductDetail);
  }

  //#region Data transfer getter/setter.
  public setSupplierProduct(value: any): void {
    this.supplierProductRecord.next(value);
  }

  public getSupplierProduct(): Observable<any> {
    return this.supplierProductRecord;
  }

  // getSupplierData():Promise<any>{
  //   let apiUrl = `${ApiUrlConstants.getSupplierData}`
  //   return this.webApiService.getRequest<any>(apiUrl)
  // }

     // Fetch supplier data with limit, offset, and optional search parameter
     getSupplierData(limit: number, offset: number, searchParam: string = ''): Observable<{ rows: any[], totalElements: number }> {
      const apiUrl = `/settings/supplier/data?limit=${limit}&offset=${offset}&searchParam=${encodeURIComponent(searchParam)}`;
      return from(this.webApiService.getRequest1<{  rows: any[], totalElements: number }>(apiUrl))
      
    }

  /** Checks whether the selected supplier is valid or not */
  isValidSupplier(_selectedSupplier: Supplier) {
    return new Promise<Supplier | null>((resolve, reject) => {
      if (
        _selectedSupplier !== null &&
        _selectedSupplier !== undefined &&
        _selectedSupplier
      ) {
        this.getSupplierById(_selectedSupplier.id).then((dbSupplier) => {
          if (
            dbSupplier.id == _selectedSupplier.id &&
            dbSupplier.vsSupplierCode == _selectedSupplier.vsSupplierCode &&
            dbSupplier.companyName == _selectedSupplier.companyName
          ) {
            resolve(dbSupplier);
          } else {
            this.snack.open(`${"Selected Supplier Not Valid!"}`, "OK", {
              duration: 3000,
            });
          }
        }).catch((error: HttpErrorResponse) => {
          //console.log(error);
          this.snack.open(`${"Selected Supplier Not Valid!"}`, "OK", {
            duration: 3000,
          });
          reject("supplier rejected");
        });
      } else {
        resolve(null);
      }
    });
  }
}