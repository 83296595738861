import { Component, OnInit, OnDestroy } from "@angular/core";
import { IMenuItem, NavigationService } from "../../../shared/services/navigation.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "../../../shared/services/layout.service";
import { UserCookieObj, UsersService } from '../../../views/user/users.service';
import { CookieService } from "ngx-cookie-service";
import { HttpErrorResponse } from "@angular/common/http";
import { FullLoaderService } from "../../../shared/services/full-loader.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
  styleUrls: ["./sidebar-side.component.css"],
})
export class SidebarSideComponent implements OnInit, OnDestroy {
  public menuItems!: IMenuItem[];
  public hasIconTypeMenuItem!: boolean;
  public iconTypeMenuTitle!: string;
  private menuItemsSub!: Subscription;
  public layoutConf!: ILayoutConf;
  user: UserCookieObj;

  constructor(
    private navService: NavigationService,
    private layout: LayoutService,
    private usersService: UsersService,
    private cookieService: CookieService,
    private fullLoaderService: FullLoaderService,
    private router: Router,
  ) {
    this.user = JSON.parse(this.cookieService.get("user"));
  }

  ngOnInit() {
    this.checkAndToggleClass();
    window.addEventListener('resize', this.checkAndToggleClass.bind(this));
    this.user = JSON.parse(this.cookieService.get("user"));
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
    window.removeEventListener('resize', this.checkAndToggleClass.bind(this)); // Cleanup
 
  }

  checkAndToggleClass(): void {
    const tobyElement = document.querySelector('.toby');

    if (tobyElement) {
      if (window.innerWidth < 1199) {
        tobyElement.classList.add('coll2');
      } else {
        tobyElement.classList.remove('coll2');
      }
    } else {
      console.warn('Element with class "toby" not found.');
    }
  }
 
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  logOut() {
    try {
      this.fullLoaderService.showLoader();
      let userId = JSON.parse(this.cookieService.get("tokenId"));
      if (userId !== null && userId !== "" && typeof userId !== "undefined") {
        this.usersService.logoutCurrentUser(userId).subscribe(
          (_userDetail:any) => {
            localStorage.clear();
            this.cookieService.deleteAll("/");
            this.fullLoaderService.hideLoader();
            setTimeout(() => {
              this.router.navigate(['/auth/signin']);
            }, 0);
          },
          (_httpErrorResponse: HttpErrorResponse) => {
            this.cookieService.deleteAll("/");
            this.fullLoaderService.hideLoader();
            setTimeout(() => {
              this.router.navigate(['/auth/signin']);
            }, 0);
          }
        );
      } else {
        this.fullLoaderService.hideLoader();
        this.cookieService.deleteAll("/");
        setTimeout(() => {
          this.router.navigate(['/auth/signin']);
        }, 0);
      }
    } catch (err) {
      this.fullLoaderService.hideLoader();
      this.cookieService.deleteAll("/");
      setTimeout(() => {
        this.router.navigate(['/auth/signin']);
      }, 0);
    }
  } 


  toggleCollapsibleClass(): void {
    const sidenav = document.querySelector('app-sidenav');   
    if (sidenav) {
        sidenav.classList.toggle('collapsible');
    } else {
        console.warn('Element with class "app-sidenav" not found.');
    }
}

toggleClass(){
   const sidebar = document.querySelector('.toby');
   if (sidebar) {
    sidebar.classList.toggle('collapsible');
} else {
    console.warn('Element with class "collapsible" not found.');
}
}

}
