import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MdePopoverModule } from "@muntazir86/material-extended";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

// Angular Material Modules
import { MatRippleModule, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

// Ngx-Charts Module
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Other Modules
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { GoogleMapsModule } from '@angular/google-maps';

// Custom Services and Modules
import { SharedModule } from './shared/shared.module';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';

// Components
import { AppComponent } from './app.component';
import { RfqGenerateFormComponent } from './views/dashboard/rfq/rfq-generate-form/rfq-genearte-form.component';
import { RfqCartComponent } from './views/dashboard/rfq/rfq-cart/rfq-cart.component';
import { SearchComponent } from './views/search/search/search.component';
import { SupplierDetailComponent } from './views/settings/suppliers/supplier-detail/supplier-detail.component';
import { SupplierProfileComponent } from './views/supplier/supplier-profile/supplier-profile.component';
import { RfqGenerateFormHeaderComponent } from './views/dashboard/rfq/rfq-generate-form-header/rfq-genearte-form-header.component';
import { RfqGenerateFormPaymentTermsComponent } from './views/dashboard/rfq/rfq-generate-form-payment-terms/rfq-genearte-form-payment-terms.component';
import { SupplierDashboardComponent } from './views/dashboard/supplier/supplier-dashboard/supplier-dashboard.component';
import { ImpaDashboardComponent } from './views/dashboard/impa/impa-dashboard/impa-dashboard.component';
import { RfqGenerateFormLineItemsComponent } from './views/dashboard/rfq/rfq-generate-form-line-items/rfq-genearte-form-line-items.component';
import { ImpaDashboardSubcategoryComponent } from './views/dashboard/impa/impa-dashboard-subcategory/impa-dashboard-subcategory.component';
import { SupplierDialogComponent } from './views/settings/suppliers/supplier-dialog/supplier-dialog.component';
import { SuppliersComponent } from './views/settings/suppliers/suppliers.component';
//import { AdminDashboardComponent } from './views/dashboard/admin/admin-dashboard/admin-dashboard.component';
import { AdminModule } from './views/dashboard/admin/admin.module'; // Adjust the path as necessary
// Routing Config
import { rootRouterConfig } from './app.routing';
import { RfqCacheService } from './views/dashboard/rfq-cache.service';
import { PdfViewerModule } from 'ng2-pdf-viewer'; 

import { SupplierProfileAboutComponent } from './views/settings/suppliers/supplier-profile-about/supplier-profile-about.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    RfqGenerateFormComponent,
    RfqCartComponent,
    SearchComponent,
    SupplierDetailComponent,
    SupplierProfileComponent,
    RfqGenerateFormHeaderComponent,
    RfqGenerateFormPaymentTermsComponent,
    SupplierDashboardComponent,
    SuppliersComponent,
    ImpaDashboardComponent,
    RfqGenerateFormLineItemsComponent,
    ImpaDashboardSubcategoryComponent,
    SupplierDialogComponent,
    SupplierProfileAboutComponent
    //AdminModule
    //AdminDashboardComponent,  // Include the AdminDashboardComponent here
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AdminModule,
    // Angular Material Modules
    MatRippleModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatStepperModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    PdfViewerModule,
    // Ngx-Charts Module
    NgxChartsModule,

    // Other Modules
    NgxFileDropModule,
    NgxDatatableModule,
    GoogleMapsModule,
    MdePopoverModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig),
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    CookieService,
    RfqCacheService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }